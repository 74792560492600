.pl-footer {
    height: 260px;
    color: white;
    background-color: #311100;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 40px;

    a {
        margin: 0 16px;
    }

    img {
        margin-bottom: 32px;
    }
}