.Industry-export-section {
    position: relative;
    background: url("../../../../images/Export-Industry.png") no-repeat fixed;
    background-size: cover;
    padding: 120px 0;

    img {
        width: 100%;
    }

    .export-section-content {
        top: 100px;
        font-size: 18px;
        width: 100%;
        color: white;

        .export-details {
            max-width: 500px;
        }

        h3 {
            font-weight: bold;
            color: white;
            font-size: 32px;
        }
    }
}