.hero-section-container {
    width: 100%;
    max-width: 1330px;
    min-height: 0;
    margin-left: auto;
    margin-right: auto;
    padding-top: 80px;
    padding-bottom: 110px;
    padding-left: 40px;
    padding-right: 40px;

    display: flex;
    align-items: center;
    justify-content: center;

    .slide {
        z-index: 0 !important;
    }

    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;

        .text-content {
            .hero-content {
                max-width: unset !important;
            }
        }

        .image-content {
            padding-bottom: 80px;
            margin-left: 0px !important;
            margin-right: 0px !important;

            .hero-image {
                width: 100% !important;
                border-radius: 20px !important;
            }
        }
    }

    .text-content {
        flex: 1;

        .hero-text {
            text-transform: none;
            margin-bottom: 18px;
            font-size: 39px;
            line-height: 1.2em;
            font-weight: 800;
            margin-top: 0;
        }

        .hero-subtext2 {
            text-transform: none;
            margin-bottom: 18px;
            font-size: 24px;
            line-height: 1.2em;
            font-weight: 800;
            margin-top: 0;
            max-width: 450px;
        }

        .hero-content {
            max-width: 550px;
        }

        .hero-content-text {
            color: #000;
            font-size: 18px;
            font-weight: 300;
        }

        .hero-button-row {
            margin: 40px 0px 20px 0px;

            @media screen and (max-width: 768px) {
                text-align: center;
            }

            .btn {
                color: #fff;
                text-align: center;
                text-transform: uppercase;
                background-color: #ff4e00;
                background-image: linear-gradient(90deg, #ff4e00, #fd7f00);
                border-radius: 10px;
                border: none;
                flex: none;
                margin-bottom: 8px;
                padding: 10px 48px;
                font-size: 18px;
                font-weight: 700;
                line-height: 1.5;
                transition: margin .2s, padding .2s, filter .2s, transform .2s, border-color .2s, color .2s, background-color .2s;
            }

            .btn:hover {
                filter: contrast(130%);
                background-color: #df4e13;
                margin-left: -4px;
                padding-left: 52px;
                padding-right: 52px;
            }

            // .book-demo-btn {
            //     background: #ff8400;
            //     color: white;
            // }

            .all-features-btn {
                background: white;
                color: #fd7f00;
                text-align: center;
                border: none;
                flex: none;
                margin-bottom: 8px;
                padding: 10px 24px;
                font-size: 18px;
                font-weight: 700;
                line-height: 1.5;
                transition: margin .2s, padding .2s, filter .2s, transform .2s, border-color .2s, color .2s, background-color .2s, letter-spacing .2s;
            }

            .all-features-btn:hover {
                background-color: white;
                margin: 0 0 8px 0;
                padding: 10px 24px;
                letter-spacing: 0.25px;
            }
        }

        @media screen and (max-width: 768px) {
            .rating-image {
                margin: 0 auto;
            }
        }
    }

    .image-content {
        flex: 1;
        display: flex;
        justify-content: center;
        margin-left: 30px;
        margin-right: -30px;

        .hero-image {
            width: 90%;
            border-radius: 80px;
            display: block;
        }
    }
}

.sl-slider-text {
    margin: 40px 0px;
    font-size: 35px;
    line-height: 1.25em;
    font-weight: 800;
    text-align: center;
    text-transform: none;
}


// ProjectLoop Slider
@mixin white-gradient {
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

$animationSpeed: 15s;

// Animation
@keyframes scroll {
    0% {
        transform: translateX(200px);
    }

    100% {
        transform: translateX(calc(-250px * 8))
    }
}


// Styling
.sl-slider {
    background: white;
    height: 100px;
    overflow: hidden;
    position: relative;
    max-width: 1330px;
    margin: 100px auto 140px;

    &::before,
    &::after {
        @include white-gradient;
        content: "";
        height: 100px;
        position: absolute;
        width: 200px;
        z-index: 2;
    }

    &::after {
        right: 0;
        top: 0;
        transform: rotateZ(180deg);
    }

    &::before {
        left: 0;
        top: 0;
    }

    .sl-slide-track {
        animation: scroll $animationSpeed linear infinite;
        display: flex;
        width: calc(250px * 14);
    }

    .slide {
        height: 100px;
        width: 250px;
    }
}

.feature-highlight-section {
    color: #fff;
    background-color: #f68343;
    min-height: 600px;
    padding-top: 80px;
    padding-bottom: 80px;

    .feature-highlight-container {
        max-width: 1330px;
        margin: auto;
        text-align: center;
        background-color: transparent;
        margin-top: 0;
        padding-top: 100px;
        padding-bottom: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @media screen and (max-width: 1024px) {
            .feature-highlight-text {
                padding: 10px;
            }

            .feature-highlight-grid {
                flex-direction: column;

                .feature-card {
                    width: unset !important;
                }
            }
        }

        .btn {
            color: #fff;
            text-align: center;
            text-transform: uppercase;
            background-color: #fc8543;
            background-image: linear-gradient(90deg, #fc8543, #63c7f9);
            border-radius: 10px;
            border: none;
            flex: none;
            margin-bottom: 8px;
            padding: 10px 48px;
            font-size: 18px;
            font-weight: 700;
            line-height: 1.5;
            transition: margin .2s, padding .2s, filter .2s, transform .2s, border-color .2s, color .2s, background-color .2s;
        }

        .btn:hover {
            filter: contrast(130%);
            background-color: #df4e13;
            margin-left: -4px;
            padding-left: 52px;
            padding-right: 52px;
        }

        .feature-highlight-text {
            margin-bottom: 12px;
            font-size: 35px;
            line-height: 1.25em;
            font-weight: 800;
            display: block;
            margin-top: 0;
            color: white;
            text-transform: none;
        }

        .feature-highlight-grid {
            display: flex;
            justify-content: space-evenly;
            width: 100%;
            margin-top: 40px;


            .feature-card {
                margin: 20px;
                width: 100%;
                background-color: #ff8f50;
                color: #fff;
                text-align: center;
                border-radius: 20px;
                flex-direction: column;
                align-items: center;
                padding: 40px 32px;
                transition: transform .2s, opacity .2s;
                display: flex;
                overflow: hidden;
                box-shadow: 0 12px 40px rgba(0, 0, 0, .04);

                .image {
                    margin-bottom: 30px;
                }

                .number-stats-image {
                    margin-bottom: 10px;
                }

                .title {
                    font-weight: 800;
                    color: #fff;
                    display: block;
                    margin-top: 0;
                    margin-bottom: 20px;
                    font-size: 22px;
                    line-height: 30px;
                    text-transform: none;
                }

                .number-stats-count {
                    margin-bottom: 12px;
                    font-size: 60px;
                    line-height: 1.2em;
                }

                .sub-text {
                    margin-bottom: 10px;
                    font-size: 18px;
                    line-height: 1.4em;
                }
            }
        }
    }
}

.sl-parallax-container {
    display: flex;
    justify-content: center;

    .export-details {
        max-width: 800px !important;
        text-align: center;

        .logo {
            width: 350px;
        }

        h2 {
            font-size: 35px;
            line-height: 1.25em;
            font-weight: 800;
            display: block;
            margin-top: 0;
            color: white;
            text-transform: none;
            margin-bottom: 100px;
        }

        p {
            margin-bottom: 60px;
            font-size: 24px;
        }
    }
}

.home-demo-btn {
    text-transform: none !important;
    text-align: center;
    color: #FC8543;
    text-transform: uppercase;
    border-radius: 10px;
    margin-bottom: 8px;
    padding: 10px 48px;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.5;
    transition: margin .2s, padding .2s, filter .2s, transform .2s, border-color .2s, color .2s, background-color .2s;
    box-shadow: 0 4px 5px rgba(0, 0, 0, .05);
}

.home-demo-btn:hover {
    filter: contrast(110%);
    margin-left: -4px;
    margin-right: -4px;
    padding-left: 24px;
    padding-right: 24px;
    box-shadow: 0 5px 6px rgba(0, 0, 0, .05);
}

.features-homepage-wrapper {
    padding-top: 40px;

    .fh-container {
        margin: 0 auto;
        max-width: 1300px;
        display: flex;
        flex-direction: column;
        padding-bottom: 50px;

        .hero-text {
            text-transform: none;
            margin-bottom: 50px;
            font-size: 46px;
            font-weight: 700;
            line-height: 1.2em;
            text-align: center;

            .blue-highlight {
                color: #fc8543;
                background-image: linear-gradient(rgba(255, 255, 255, 0) 80%, #fc854300 60%, #fc85432e);
                padding-left: 6px;
                padding-right: 6px;
            }
        }

        .sub-text {
            color: #000;
            font-family: Roboto, sans-serif;
            font-size: 18px;
            font-weight: 300;
            display: block;
            text-align: center;
        }
    }

    .feature-list-container {
        margin: 0 auto;
        padding-top: 80px;
        padding-bottom: 150px;
        width: 100%;
        max-width: 1330px;
        height: auto;
        grid-column-gap: 32px;
        grid-row-gap: 32px;
        grid-template-rows: min-content;
        grid-template-columns: 1fr 1fr 1fr;
        grid-auto-rows: min-content;
        grid-auto-columns: 1fr;
        display: grid;
        overflow: visible;

        @media screen and (max-width: 1330px) {
            padding: 60px;
        }

        @media screen and (max-width: 1400px) {
            grid-template-columns: 1fr 1fr;
        }

        @media screen and (max-width: 850px) {
            grid-template-columns: 1fr;
        }

        .feature-list-item {
            .feature-card {
                width: 100%;
                height: 100%;
                background-color: #f2f5f7;
                color: #000000;
                text-align: center;
                border-radius: 20px;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                padding: 42px;
                transition: background-color .2s, transform .2s, opacity .2s;
                display: flex;
                position: relative;
                overflow: hidden;
                box-shadow: 0 12px 40px rgba(0, 0, 0, .04);

                .card-header {
                    margin-bottom: 44px;
                    font-size: 35px;
                    font-weight: 700;
                    line-height: 1.25em;
                }

                .card-subtext {
                    color: #000;
                    font-size: 18px;
                    font-weight: 300;
                    display: block;
                    margin-bottom: 74px;
                }

                .feature-card-cta {
                    color: #fc8543;
                    background-position: 100%;
                    background-repeat: no-repeat;
                    background-size: auto;
                    margin-bottom: 8px;
                    padding-top: 2px;
                    padding-bottom: 2px;
                    font-size: 18px;
                    font-weight: 700;
                    transition: all .2s, padding .2s, background-color .3s, opacity .2s, color .2s;
                    display: inline-block;
                    position: relative;
                    top: auto;
                    bottom: 0%;
                    left: 0%;
                    right: 0%;
                    overflow: hidden;
                }

                .feature-card-cta:hover {
                    padding: -10px -10px;
                    letter-spacing: 0.5px;
                }
            }

            .feature-card:hover {
                background-color: #e9ecee;
                box-shadow: 0 12px 40px rgba(0, 0, 0, .04);
            }
        }
    }
}